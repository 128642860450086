<template>
	<div class="page">
		<div v-if="$hasAccess('market.task/index')" @click="openRoute('market-task-list')">任务配置</div>
		<div v-if="$hasAccess('market.chargecoupon/list')" @click="openRoute('market-charge-list')">充电返券</div>
		<div v-if="$hasAccess('market.gift/index')" @click="openRoute('market-gift-list')">充电度数</div>
		<div v-if="$hasAccess('market.activitycommon/invite')" @click="openRoute('market-activitycommon-invite')">邀请有礼</div>
		<div v-if="$hasAccess('market.groupprice/index')" @click="openRoute('market-group-price')">电站优惠</div>
		<div v-if="$hasAccess('market.group/list')" @click="openRoute('market-group-list')">特惠站点</div>
		<div v-if="$hasAccess('market.activitycommon/discount')" @click="openRoute('market-activitycommon-discount')">随机立减</div>
		<div v-if="$hasAccess('market.activitycommon/digit')" @click="openRoute('market-activitycommon-digit')">数币活动</div>
		<div v-if="$hasAccess('market.activitycommon/list')" @click="openRoute('market-activitycommon-list')">车险活动</div>
		<div v-if="$hasAccess('market.activitycommon/newcar')" @click="openRoute('market-activitycommon-newcar')">新车活动</div>
		<div v-if="$hasAccess('market.yearbill/index')" @click="openRoute('market-yearbill-index')">年度账单</div>
		<div v-if="$hasAccess('market.plan/index')" @click="openRoute('market-plan-list')">充值优惠</div>
		<div v-if="$hasAccess('market.activity/index')" @click="openRoute('market-activity-list')">活动</div>
		<div v-if="$hasAccess('market.activitycommon/coupon')" @click="openRoute('market-activitycommon-coupon')">优惠券活动</div>
		<div v-if="$hasAccess('market.activitycommon/lottery')" @click="openRoute('market-activitycommon-lottery')">抽奖活动</div>
		<div v-if="$hasAccess('market.coupon/urlindex')" @click="openRoute('market-coupon-url')">领券</div>
		<div v-if="$hasAccess('vip.vipcfg/boxlunch')" @click="openRoute('vip-box-lunch')">会员权益1</div>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activity-action',
		components: {
			Tables
		},
		data() {
			return {
			}
		},
		computed: {
		},
		mounted() {
		},
		activated() {},
		methods: {
			openRoute(url){
				this.$router.push({
					name: url,
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
.page{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	div{
		text-align: center;
		width: 180px;
		height: 60px;
		line-height: 60px;
		background: #eee;
		margin: 10px;
	}
}
</style>
